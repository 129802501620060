import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../images/viosa.png";
import { ThemeContext } from "../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { deleteBatchById, exportBatches, getAllBatches, getAllColleges, getBatchesByCollegeId } from "../services/apis";
import Loader from "../Common/Loader/Loader";
import CommonTable from "../Common/Table/CommonTable";
import { Button, Form, Modal, ModalBody } from "react-bootstrap";
import DeleteModal from "../Common/Components/DeleteModal";
import { debounce } from "lodash";
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
const Batches = () => {
    const [allBatches, setallBatches] = useState([]);
    const [loadingBatches, setloadingBatches] = useState(false)
    const [collegeId, setcollegeId] = useState(null)
    const [allColleges, setallColleges] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchQuery, setsearchQuery] = useState('')

    const fetchBatches = async (searchQuery = '', currentPage = 1, perPage = 10) => {
        try {
            // setloadingBatches(true)
            const query = {
                params: { perPage, currentPage, search: searchQuery },
            }
            const res = await getAllBatches(query)
            setTotalRows(res.data.data.totalRows)
            setallBatches(res.data.data.teams)
            // setloadingBatches(false)
        } catch (error) {
            setloadingBatches(false)
            console.log(error)
        }
    }

    const debouncedLoadData = useCallback(debounce(fetchBatches, 300), []);


    useEffect(() => {
        debouncedLoadData(searchQuery, currentPage, perPage);
    }, [searchQuery, currentPage, perPage, debouncedLoadData]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };



    const fetchColleges = async () => {
        try {
            const res = await getAllColleges()
            setallColleges(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (!collegeId) {

            fetchColleges()
        }
    }, [])
    const fetchBatchesOfCollege = async () => {
        try {
            setloadingBatches(true)
            const res = await getBatchesByCollegeId(collegeId)
            setallBatches(res.data.data)
            // console.log(res.data.data.length)
            setTotalRows(res.data.data.length)
            setloadingBatches(false)
        } catch (error) {
            setloadingBatches(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if (!!collegeId) {
            fetchBatchesOfCollege()
        }
        if (collegeId === "") {
            fetchBatches()
        }
    }, [collegeId])



    const handleChange = (e) => {
        const { name, value } = e.target
        setcollegeId(value)
    }


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2,
            // cellExport:row => ({Title: row.Title, Example: row.Example})

        },
        {
            name: 'Study Year',
            selector: row => row.studyclass,
            sortable: true
        },
        {
            name: 'Academic Year',
            selector: row => row.academicYear,
            sortable: true
        },
        // {
        //     name: 'Passout Year',
        //     selector: row => row.year,
        //     sortable: true
        // },
        {
            name: 'Students',
            selector: row => <div className="gap-2 d-flex w-100">

                <button className="common-btn rounded-2 p-2 w-100 " onClick={() => {
                    setallStudents(row.students)
                    ShowStudentsModal()
                }}>View
                    <span className="ms-2">
                        {
                            row.students.length
                        }
                    </span>
                </button>
            </div>,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <div className="d-flex gap-3">
                <Link to={`/batches/${row._id}/edit`} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </Link>

                <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
                    ShowDeleteModal(row)
                }} ><MdDelete fontSize={24} style={{ color: "red" }} /></span>
            </div>,
        },
    ]


    // <----------------------------------------delete batch-------------------------------------------->

    const [showDelete, setshowDelete] = useState(false)
    const [DeletionObject, setDeletionObject] = useState()

    const ShowDeleteModal = (value) => {
        setDeletionObject(value)
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }

    const handleDeleteDecision = async (value) => {
        try {
            if (value === true) {
                const newArr = allBatches?.filter((item, index) => item._id !== DeletionObject?._id)
                setallBatches(newArr)
                await deleteBatchById(DeletionObject?._id)
            }
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }


    // <--------------------------------------------student table-------------------------------------->


    const [studentsModal, setStudentsModal] = useState(false);
    const [allStudents, setallStudents] = useState([])
    const ShowStudentsModal = () => setStudentsModal(true);
    const CloseStudentsModal = () => setStudentsModal(false);


    const StudentTableColumn = [
        //     {
        //     name: 'S.No',
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        // },
        {
            name: 'Name',
            selector: row => row.name,
            cellExport: (row) => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: 'Phone',
            selector: row => row.phoneNumber,
            cellExport: (row) => row.phoneNumber,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            cellExport: (row) => row.email,
            sortable: true
        },
    ]





    // const csvData = allBatches?.map((row, index) => ({
    //     "S No.": index + 1,
    //     "Name": row.name,
    //     "Study Year": row.studyclass,
    //     "Academic Year": row.academicYear,
    // }));




    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        // handleSearchChange()
    }, [searchQuery, allBatches]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(allBatches);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = allBatches.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }


    // students

    const csvStudentData = allStudents?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Email": row.email,
        "Phone": row.phoneNumber,
    }));


    const [searchStudentQuery, setsearchStudentQuery] = useState('')

    const [FilteredStudentData, setFilteredStudentData] = useState([])
    useEffect(() => {
        handleSearchStudentChange()
    }, [searchStudentQuery, allStudents]);

    const handleSearchStudentChange = async (e) => {
        if (setsearchStudentQuery === '') {
            setFilteredStudentData(allStudents);
        } else {
            const lowercasedQuery = searchStudentQuery.toLowerCase();
            const filteredResults = allStudents.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredStudentData(filteredResults);
        }
    }




    //fetch batches to export

    const [fetchingCSVdataLoading, setfetchingCSVdataLoading] = useState(false)
    const [csvData, setcsvData] = useState([])

    const fetchUserToExport = async () => {
        try {
            setfetchingCSVdataLoading(true)
            const query = {
                params: { search: searchQuery, collegeId },
            }
            const response = await exportBatches(query)
            setcsvData(response.data.users)
            console.log(response)
            const formattedData = response?.data?.users?.map(item => ({
                "Name": item.name,
                "Study Year": item.studyclass,
                "Academic Year": item.academicYear,
            }));

            const csv = unparse(formattedData);

            setfetchingCSVdataLoading(false)
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `UserData.csv`);
        } catch (error) {
            setfetchingCSVdataLoading(false)
            console.log(error)
        }
    }

    return (
        <>
            <section >
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                        <select className="form-select form-select-lg border border-dark" aria-label="Default select example" onChange={handleChange}>
                            <option value={""} >Select College</option>
                            {
                                allColleges?.map((item, index) => {
                                    return <option key={index} value={item._id}>{item?.collegename}</option>
                                })
                            }
                        </select>
                    </Form.Group>
                    <Link to={'/batches/add'} className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#fea302 " }}>Create Batches</Link>
                </div>
                {
                    loadingBatches ?
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div> :
                        <>
                            <CommonTable
                                data={allBatches}
                                columns={columns}
                                csvData={csvData}
                                csvName={"Batches-Data"}
                                setsearchQuery={setsearchQuery}
                                fetchingCSVdataLoading={fetchingCSVdataLoading} fetchUserToExport={fetchUserToExport}
                                tableType="user"
                                totalRows={totalRows}
                                handlePageChange={handlePageChange}
                                handlePerRowsChange={handlePerRowsChange}
                            />
                        </>
                }

            </section >
            <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.name} />

            {/* modal for students */}
            <Modal
                show={studentsModal}
                onHide={CloseStudentsModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"

                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Student Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonTable data={FilteredStudentData} csvData={csvStudentData} csvName={"Batch-Student-Data"} setsearchQuery={setsearchStudentQuery} columns={StudentTableColumn} />
                </Modal.Body>
            </Modal>
        </>
    );
};





export default Batches;



