import * as XLSX from "xlsx";

export const exportToExcel = (data) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the data to an array of arrays
  const dataArray = data.map((item) => Object.values(item));
  // Add the data array to a new worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Save the workbook as an Excel file
  XLSX.writeFile(workbook, "data.xlsx");
  console.log("Exporting data to Excel:", data);
};
