import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Spinner } from 'react-bootstrap'
import Loader from '../Common/Loader/Loader';
import CommonTable from '../Common/Table/CommonTable';
import { checkSidebarTool, getAllCollegesStudents, getAllInterview } from '../services/apis';

function CollegeCourses() {
    const [coursesData, setcoursesData] = useState([])
    const [loading, setloading] = useState(false)
    const navigate = useHistory()

    const fetchTools = async () => {
        try {

            setloading(true)
            const payload = {
                collegeId: localStorage.getItem("userId"),
            };
            const res = await checkSidebarTool(payload);
            console.log(res)
            setcoursesData(res.data.tools)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        fetchTools()
    }, [])

    const handleCollegeDelete = async () => {
        try {
            if (value === true) {
                const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
                setInterviewsData(newArr)
                await deleteBatchById(DeletionObject?._id)
            }
            console.log(value)
        } catch (error) {
            console.log(error)
        }
    }


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2
        },

        {
            name: 'Status',
            selector: row => <div>Active</div>,
            sortable: true
        },
        // {
        //     name: 'Action',
        //     selector: row => <div className="d-flex gap-3">
        //         <button onClick={() => {
        //             navigate.push(`/interview-pro/interview/add/${row?._id}/new-mock`)
        //         }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

        //         <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
        //         }} ><MdDelete onClick={() => ShowDeleteModal(row)} fontSize={24} style={{ color: "red" }} /></span>
        //     </div>,
        // },
    ]


    // deletion 


    // <----------------------------------------delete batch-------------------------------------------->

    const [showDelete, setshowDelete] = useState(false)
    const [DeletionObject, setDeletionObject] = useState()

    const ShowDeleteModal = (value) => {
        setDeletionObject(value)
        setshowDelete(true)
    }
    const HideDeleteModal = () => {
        setshowDelete(false)
    }
    // const [searchQuery, setsearchQuery] = useState('')
    // const [filteredData, setFilteredData] = useState();

    // useEffect(() => {
    //     if (searchQuery === '') {
    //         setFilteredData(coursesData);
    //     } else {
    //         const lowercasedQuery = searchQuery.toLowerCase();
    //         const filteredResults = coursesData?.filter(item =>
    //             item.name.toLowerCase().includes(lowercasedQuery)
    //         );
    //         setFilteredData(filteredResults);
    //     }
    // }, [searchQuery, coursesData]);


    // const csvData = collegeData?.map((row, index) => ({
    //     "S No.": index + 1,
    //     "Name": row.collegename,
    //     "Email": row.email,
    //     "Phone No.": row.phoneNumber,
    //     "Status": row.status ? "Active" : "Deactive"
    // }));


    const [searchQuery, setsearchQuery] = useState('')

    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, coursesData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(coursesData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = coursesData.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }


    const csvData = FilteredData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Status": row?.status,
    }));


    return (
        <div>
            <div className="d-flex w-100 gap-4 mb-3  align-items-center">
                {/* <input type="search" placeholder='Search Courses' onChange={(e) => setsearchQuery(e.target.value)} className='p-3 rounded-4 w-25' /> */}
            </div>

            {
                loading ? <Loader /> : <CommonTable data={FilteredData}
                    setsearchQuery={setsearchQuery}
                    columns={columns}
                    csvData={csvData} csvName={"Courses-Data"} />

            }
            {/* <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.jobtitle} /> */}

        </div>
    )
}

export default CollegeCourses