// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-specific-details h4{
    margin: 0;
    cursor: pointer;
    padding: 7px 8px;
    color: white;
}

.users-specific-details{
    width: 142px !important;
    margin: 0px 20px;
    margin-top: 20px !important;
    height: 40px;
    background-color: gray;
    border-radius: 3px;
}
.users-specific-details h4{
    margin: 5px;
    padding: 7px 8px;
    color: white;
}
.users-specific-details.active{
    background-color: rgb(255, 18, 148);
}

.card-users-details{
    height: 175px;
    margin-top: 130px !important;

}
.teams_align{
    width: 60%;
    margin-left: 10%;
}`, "",{"version":3,"sources":["webpack://./src/jsx/components/User/userdetails.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,2BAA2B;IAC3B,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,4BAA4B;;AAEhC;AACA;IACI,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".users-specific-details h4{\n    margin: 0;\n    cursor: pointer;\n    padding: 7px 8px;\n    color: white;\n}\n\n.users-specific-details{\n    width: 142px !important;\n    margin: 0px 20px;\n    margin-top: 20px !important;\n    height: 40px;\n    background-color: gray;\n    border-radius: 3px;\n}\n.users-specific-details h4{\n    margin: 5px;\n    padding: 7px 8px;\n    color: white;\n}\n.users-specific-details.active{\n    background-color: rgb(255, 18, 148);\n}\n\n.card-users-details{\n    height: 175px;\n    margin-top: 130px !important;\n\n}\n.teams_align{\n    width: 60%;\n    margin-left: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
