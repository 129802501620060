import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import Loader from "../../Common/Loader/Loader";
import CommonTable from "../../Common/Table/CommonTable";
import { getAllCandidates, getToolsUserForAdmin, getToolsUsers } from "../../services/apis";

const InterviewCandidate = () => {
    const [loading, setloading] = useState(false)
    const [candidateData, setcandidateData] = useState([])

    const navigate = useHistory()


    const fetchCandidate = async () => {
        try {
            const id = 0;
            setloading(true)
            const payload = {
                tool: "interview-pro"
            }
            const res = await getToolsUserForAdmin(payload)
            console.log(res)
            // setInterviewsData(res.data.interviews)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCandidate()
    }, [])

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Job Title',
            selector: row => row.jobtitle,
            sortable: true,
            grow: 2
        },
        {
            name: 'Job Category',
            selector: row => row.jobcategory,
            sortable: true
        },
        {
            name: 'Domain',
            selector: row => row.domain,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <div className="d-flex gap-3">
                <button onClick={() => {
                    navigate.push(`/interview-pro/interview/add/${row?._id}/new-mock`)
                }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

                <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
                }} ><MdDelete onClick={() => ShowDeleteModal(row)} fontSize={24} style={{ color: "red" }} /></span>
            </div>,
        },
    ]

    return (
        <>
            <section>
                {
                    loading ? <Loader /> : <CommonTable data={candidateData}
                        columns={columns} />

                }
            </section>
        </>
    );
};
export default InterviewCandidate;



