import React from "react";
import TableShow from "../../TableShow";
import { Session } from "../Session";

export const SingleSession = () => {
  return (
    <div>
      {/* <Session /> */}
      <TableShow />
    </div>
  );
};
