import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from 'react-bootstrap/Button';
import Loader from '../Common/Loader/Loader';
import CommonTable from '../Common/Table/CommonTable';
import { getAllCollegesStudents, getAllInterview, getBatchesByCollegeId } from '../services/apis';
import { Form, Modal, ModalBody } from "react-bootstrap";

function CollegeBatches() {
    const [batchData, setbatchData] = useState([])
    const [loading, setloading] = useState(false)
    const collegeId = localStorage.getItem('userId')
    const navigate = useHistory()


    const fetchBatches = async () => {
        try {
            setloading(true)
            const res = await getBatchesByCollegeId(collegeId)
            console.log(res)
            setbatchData(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        fetchBatches()
    }, [])




    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: 'Courses',
            selector: row => <div><button className='common-btn rounded-2 p-2' onClick={() => {
                setallCourse(row.course)
                ShowCourseModal()
            }}>View</button></div>,
            sortable: true,
            grow: 2
        },
        {
            name: 'Standard',
            selector: row => row.standard,
            sortable: true
        },
        {
            name: 'Passout Year',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Students',
            selector: row => <div>
                <button className='common-btn p-2 rounded-2' onClick={() => {
                    setallStudents(row.students)
                    ShowStudentsModal()
                }}>View ({row.students.length})</button>
            </div>,
            sortable: true
        },

    ]

    // course modal 
    const [courseModal, setcourseModal] = useState(false);
    const [allCourse, setallCourse] = useState([])
    const ShowCourseModal = () => setcourseModal(true);
    const CloseCourseModal = () => setcourseModal(false);


    const [studentsModal, setStudentsModal] = useState(false);
    const [allStudents, setallStudents] = useState([])
    const ShowStudentsModal = () => setStudentsModal(true);
    const CloseStudentsModal = () => setStudentsModal(false);

    const StudentTableColumn = [{
        name: 'S.No',
        selector: (row, index) => index + 1,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        grow: 2
    },
    {
        name: 'Phone',
        selector: row => row.phoneNumber,
        sortable: true
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true
    },
    ]
    // console.log(allCourse)

    // batches

    const csvData = batchData?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Standard": row.standard,
        "Passout Year": row.year,
    }));


    const [searchQuery, setsearchQuery] = useState('')

    const [FilteredData, setFilteredData] = useState([])
    useEffect(() => {
        handleSearchChange()
    }, [searchQuery, batchData]);

    const handleSearchChange = async (e) => {
        if (searchQuery === '') {
            setFilteredData(batchData);
        } else {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filteredResults = batchData?.filter(item =>
                Object.values(item).some(
                    val => val && val.toString().toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filteredResults);
        }
    }



    const csvStudentData = allStudents?.map((row, index) => ({
        "S No.": index + 1,
        "Name": row.name,
        "Email": row?.email,
        "Phone": row.phoneNumber,
    }));

    return (
        <div>
            <div className="d-flex w-100 gap-4 mb-3  align-items-center">
                {/* <input type="search" placeholder='Search Batches' onChange={(e) => setsearchQuery(e.target.value)} className='p-3 rounded-4 w-25' /> */}
            </div>

            {
                loading ? <Loader /> : <CommonTable data={FilteredData} csvData={csvData}
                    columns={columns} setsearchQuery={setsearchQuery} csvName={"College-Batches"} />
            }

            {/* student modal */}
            <Modal
                show={studentsModal}
                onHide={CloseStudentsModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"

                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Student Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonTable data={allStudents} csvData={csvStudentData} csvName={"Batch-student"} columns={StudentTableColumn} />
                </Modal.Body>
            </Modal>

            {/* course modal */}
            {/* <Modal
                show={studentsModal}
                onHide={CloseStudentsModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"

                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Student Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonTable data={allStudents} columns={StudentTableColumn} />
                </Modal.Body>
            </Modal> */}



            <Modal
                show={courseModal}
                onHide={CloseCourseModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"

                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Courses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        allCourse?.length > 0 ?
                            <ul>{
                                allCourse?.map(item => {
                                    return <li className='common-bg p-2 rounded'>{item?.name}</li>
                                })
                            }
                            </ul>
                            : "No Course Assigned"
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CollegeBatches