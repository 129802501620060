import axios from "axios";
import Cookies from "js-cookie";
const API = axios.create({
    // baseURL: "https://api-staging.viosa.in/interview-pro",
    // baseURL: "http://localhost:3200/interview-pro",
    baseURL: "https://api.viosa.in/interview-pro",
});

API.interceptors.request.use((req) => {
    if (Cookies.get("token")) {
        req.headers["token"] = Cookies.get("token");
    } else if (!!localStorage.getItem("token")) {
        req.headers["token"] = localStorage.getItem("token")
    }
    return req;
});

const AdminAPI = axios.create({
    // baseURL: "https://api-staging.viosa.in"
    baseURL: "https://api.viosa.in",
    // baseURL: "http://localhost:3200",
});

AdminAPI.interceptors.request.use((req) => {
    if (Cookies.get("token")) {
        req.headers["token"] = Cookies.get("token");
    } else if (!!localStorage.getItem("token")) {
        req.headers["token"] = localStorage.getItem("token")
    }
    return req;
});

// sidebar
export const checkSidebarTool = (payload) =>
    API.post("/interview/check-tools", payload);

// batches
export const PostBatches = (payload) =>
    API.post("/auth/college/createBatch", payload);
export const EditBatches = (payload) =>
    API.post("/auth/college/editBatch", payload);
export const getAllBatches = (query) => API.get("/auth/college/teams/all", query);
export const getBatchesByCollegeId = (id) =>
    API.get(`/auth/college/teams/college/${id}`);
export const deleteBatchById = (id) => API.delete(`/auth/college/team/${id}`);
export const getSingleBatchById = (id) => API.get(`/auth/college/team/${id}`);
export const updateUserStatus = (payload) => AdminAPI.patch(`/user/updateUserStatus/${payload.id}`, payload);

// colleges
export const PostchangeCollegePassword = (payload) => AdminAPI.post("/college/changePassword", payload);
export const PostCollege = (payload) =>
    API.post("/auth/college/signup", payload);
export const deleteCollege = (id) => API.delete(`/auth/admin/delete/college/${id}`);
export const deleteCourseAPI = (id) => AdminAPI.delete(`/admin/course/${id}`);
export const updateCollege = (payload) =>
    API.post(`/auth/college/updateprofile`, payload);
export const updateCollegeStatus = (payload) =>
    API.post(`/auth/admin/update/college/${payload.id}`, payload);
export const getAllColleges = () => API.get("/auth/college/all");
export const getAllCollegesStudents = (payload) => API.post("/auth/college/getallstudent", payload);
export const getAllDashboardData = (payload) => API.post("/auth/college/dashboard/data", payload);
export const getAllCourses = () =>
    axios.get("https://api.viosa.in/admin/course");

// interview pro
// interviews
// admin
export const getAdminInterviewProDashboardData = () =>
    API.get("/auth/admin/dashboard");
export const getAdminResumaticDashboardData = () =>
    API.get("/auth/admin/dashboard/resumatic");
export const getToolsUsers = (id) =>
    API.get(`/interview/users/${id}`);
export const getStudentReport = (payload) =>
    API.post(`/interview/report/user`, payload);
export const getAllInterview = (payload) =>
    API.get("/interview/all-interviews", payload);
export const getStudentInterview = (id) =>
    API.get(`/auth/student/details/${id}`);
export const getAllCandidates = () =>
    API.get("/interview/candidate");
export const PostInterview = (payload) =>
    API.post("/interview/createinterview", payload);
export const UpdateInterview = (payload) =>
    API.patch("/interview/admin/update-interview", payload);
export const DeleteInterviewById = (id) =>
    API.delete(`/interview/delete/${id}`);
export const InterviewReport = (data) =>
    API.get(`/interview/submitted/${data.id}`);
export const singleInterviewById = (payload) =>
    API.post(`/interview/single-interview`, payload);
export const searchInterviewByText = (payload) =>
    API.post(`/interview/search-interviews?q=${payload}`);
export const CreateSkillTypes = (payload) =>
    API.post(`/interview/createskilltype`, payload);
export const createskill = (payload) =>
    API.post(`/interview/createskill`, payload);
export const getAllkillTypes = () =>
    API.post(`/interview/getallskill`);

export const getAllSkills = (query) =>
    API.get(`/interview/skills/searchSkill/${query}`);
export const deleteSkillTypeAPI = (id) =>
    API.delete(`/interview/skilltype/delete/${id}`);
export const deleteSkillAPI = (payload) =>
    API.post(`/interview/skills/delete`, payload);


//corporate
export const getAllCorporate = () => API.get("/auth/corporate/all");
export const getSingleCorporate = (id) => API.get(`/auth/corporate/${id}`);
export const postCorporate = (payload) => API.post("/auth/corporate/signup", payload);
export const updateCorporate = (payload) => API.patch("/auth/corporate/update", payload);

// resumatic
export const updateAiCredits = (payload) =>
    AdminAPI.post(`/resumatic/admin/credit/update`, payload);

// Admin
export const getToolsUserForAdmin = (payload) =>
    AdminAPI.get(`/admin/getToolsUsersForAdmin`, payload);


// courses
export const getSingleCourse = (id) => AdminAPI.get(`/admin/course/singleCourse/${id}`);


export const AssignTeamToUser = (payload) => AdminAPI.post(`/user/assignTeam/${payload.id}`, payload);
export const AssignCollegeToUser = (payload) => AdminAPI.post(`/user/assignCollege`, payload);

// export user
export const exportUsers = (payload) => AdminAPI.get(`/user/exportUsers`, payload);
export const exportToolsUser = (payload) => AdminAPI.get(`/user/exportToolsUsers`, payload);
export const exportBatches = (payload) => AdminAPI.get(`/admin/team/exportTeams`, payload);