import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import { ToastContainer, toast } from 'react-toastify';

import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import GuestList from "./components/Dashboard/GuestList";
import GuestDetail from "./components/Dashboard/GuestDetail";
import Concierge from "./components/Dashboard/Concierge";
import Room from "./components/Dashboard/Room";
import Reviews from "./components/Dashboard/Reviews";
import Task from "./components/Dashboard/Task";

/////Demo pages
import Demo1 from "./components/Dashboard/Demo/Demo1";
import Demo2 from "./components/Dashboard/Demo/Demo2";
import Demo3 from "./components/Dashboard/Demo/Demo3";
import Demo4 from "./components/Dashboard/Demo/Demo4";
import Demo5 from "./components/Dashboard/Demo/Demo5";
//import Demo6 from './components/Dashboard/Demo/Demo6';

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";
import 'react-toastify/dist/ReactToastify.css';
/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import StaffTable from "./components/table/StaffManage/StaffTable";
import CourseTable from "./components/table/Courses/CourseTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import { CourseMain } from "./components/table/Courses/CourseMain";
import { useSelector } from "react-redux";
import { PageBuilder } from "./components/table/Courses/Chapter/PageBuilder";
import { PageBuild } from "./components/table/Courses/Chapter/PageBuild";
import { Template1 } from "./components/table/Courses/LandingPage/template1/Template1";
import { Template2 } from "./components/table/Courses/LandingPage/template2/Template2";
import { SingleSession } from "./components/table/Courses/Session/Sessions/SingleSession";
import { Session } from "./components/table/Courses/Session/Session";
import PageBuilderPreview from "./components/table/Courses/Chapter/PageBuilderPreview";
import { Category } from "./components/table/Courses/Category/Category";
import { CourseCoupon } from "./components/table/Coupon/Coupon";
import { CouponTable } from "./components/table/Coupon/CouponTable";
import { BulkUser } from "./components/table/SortingTable/BulkUser";
import UserDetails from "./components/User/UserDetails";
import Teams from "./components/User/Teams";
import AddGrapes from "./components/table/Courses/Chapter/AddGrapes";
import { BundleMain } from "./components/table/Bundle/BundleMain";
import { Revenue } from "./components/table/Revenue/Revenue";
import { Query } from "./components/query/Query";
import { Form } from "./components/form/form";
import TeamMembers from "./components/User/TeamMemers";
import InterviewDashboard from "../interview/Interview-dashboard/Interview_dashboard";
import Myinterview from "../interview/Interview-myinterview/Myinterview";
import InterviewCollege from "../interview/interview-college/InterviewCollege";
import InterviewCandidate from "../interview/interview-candidate/InterviewCandidate";
import Addinterview from "../interview/interview-add/Addinterview";
import Newinterview from "../interview/interview-add/Newinterview";
import Batches from "../batches/Batches";
import AddBatches from "../batches/Batches-add/AddBatches";
import BatchStudents from "../batches/Batches-student/BatchStudents";
import College from "../colleges/College";
import CollegeDashboard from "../Dashboard/CollegeDashboard";
import Students from "../Student/Students";
import CollegeCourses from "../colleges/CollegeCourses";
import CollegeBatches from "../colleges/CollegeBatches";
import ToolsUser from "../colleges/ToolsUser";
import Reports from "../Common/Reports/Reports";
import UserInterviews from "../interview/UserInterviews/UserInterviews";
import ViewReports from "../interview/UserInterviews/ViewReports";
import ResumaticDashboard from "../Resumatic/resumatic-dashboard/ResumaticDashboard";
import Corporate from "../corporate/Corporate";
import InterviewSkills from "../interview/Interview-skills/InterviewSkillsType";
import CreateCorporate from "../corporate/CreateCorporate";
import CollegeProfile from "../colleges/CollegeProfile";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const ROLE = localStorage.getItem("role");

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: ROLE === "ADMIN" ? Home : CollegeDashboard },
    { url: "college/students", component: Students },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "guest-list", component: GuestList },
    { url: "guest-detail", component: GuestDetail },
    { url: "concierge", component: Concierge },
    { url: "room-list", component: Room },
    { url: "reviews", component: Reviews },
    { url: "task", component: Task },
    { url: "colleges", component: College },
    { url: "college/courses", component: CollegeCourses },
    { url: "college/interview-pro/users", component: ToolsUser },
    { url: "college/interview-pro/users/:id/:interviewId/reports", component: ViewReports },
    { url: "college/interview-pro/users/:id/:id2/reports/:id3/report", component: Reports },
    { url: "college/interview-pro/users/:id/interviews", component: UserInterviews },
    { url: "college/interview-pro/myinterview", component: Myinterview },
    { url: "college/interview-pro/interview/add/new-mock", component: Newinterview },

    { url: "college/resumatic/users", component: ToolsUser },
    { url: "college/batches", component: CollegeBatches },
    { url: "college/profile", component: CollegeProfile },
    { url: "batches", component: Batches },
    { url: "batches/add", component: AddBatches },
    { url: "batches/:id/edit", component: AddBatches },
    { url: "batches/students/:id", component: BatchStudents },

    ///themes
    { url: "theme1", component: Demo1 },
    { url: "theme2", component: Demo2 },
    { url: "theme3", component: Demo3 },
    { url: "theme4", component: Demo4 },
    { url: "theme5", component: Demo5 },
    // { url: "theme6", component: Demo6 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    { url: "redux-form", component: ReduxForm },
    { url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "users", component: SortingTable },
    { url: "user/:id", component: UserDetails },
    { url: "team/:id", component: TeamMembers },
    { url: "users/teams", component: Teams },
    { url: "users/bulk", component: BulkUser },
    { url: "table-datatable-basic", component: DataTable },

    // corporate
    { url: "corporate", component: Corporate },
    { url: "corporate/new", component: CreateCorporate },
    { url: "corporate/:id/edit", component: CreateCorporate },

    { url: "table-bootstrap-basic", component: BootstrapTable },
    { url: "staffManage", component: StaffTable },
    { url: "course", component: CourseTable },
    { url: "course/:id", component: CourseMain },
    { url: "bundle/:id/:name", component: BundleMain },
    { url: "category", component: Category },
    { url: "coupons", component: CouponTable },
    { url: "create/coupon", component: CourseCoupon },
    { url: "coupon/:id", component: CourseCoupon },
    { url: "course/:id/page-builder", component: PageBuilderPreview },
    { url: "course/:id/grapes", component: AddGrapes },
    { url: "query", component: Query },
    { url: "form", component: Form },

    // interview
    { url: "interview-pro/dashboard", component: InterviewDashboard },
    { url: "interview-pro/myinterview", component: Myinterview },
    { url: "interview-pro/college", component: InterviewCollege },
    { url: "interview-pro/users", component: ToolsUser },
    { url: "interview-pro/skills", component: InterviewSkills },
    // { url: "interview-pro/skills/:id/all", component: InterviewSkills },
    { url: "interview-pro/interview/add", component: Addinterview },
    { url: "interview-pro/interview/add/new-mock", component: Newinterview },
    {
      url: "interview-pro/interview/add/:id/new-mock",
      component: Newinterview,
    },

    // Resumatic
    { url: "resumatic/dashboard", component: ResumaticDashboard },
    { url: "resumatic/users", component: ToolsUser },

    { url: "session/:id/:id/:id", component: Session },
    { url: "page", component: PageBuilderPreview },
    { url: "revenue", component: Revenue },
    { url: "multiple-session", component: Session },
    { url: "demo1", component: Template1 },
    { url: "demo2", component: Template2 },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
