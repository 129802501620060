// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #f0f0f0;
}

:root {
  --primary: #521986;
}

.active-hover:hover {
  background-color: bisque;
}

.cursor-pointer {
  cursor: pointer;
}

.active-hover-fw-bold:hover {
  color: #333;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #fea302 !important;
  border-color: #fea302 !important;
}

.highlightOption {
  background-color: white !important;
  border-color: #fea302 !important;
  color: #333 !important;
  opacity: 0.8 !important;
}

.highlightOption:hover {
  background-color: #fea302 !important;
  border-color: #fea302 !important;
}

.chip {
  background-color: #fea302 !important;
  border-color: #fea302 !important;
  overflow: hidden;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}

.form-select-lg {
  font-size: 0.8rem;
}

.form-control {
  font-size: 0.8rem;
}

.select-heading {
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}

.deznav .metismenu li a {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;;;;;EAKE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":["body {\n  background: #f0f0f0;\n}\n\n:root {\n  --primary: #521986;\n}\n\n.active-hover:hover {\n  background-color: bisque;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.active-hover-fw-bold:hover {\n  color: #333;\n}\n\n.btn-primary,\n.btn-primary:hover,\n.btn-primary:active,\n.btn-primary:visited,\n.btn-primary:focus {\n  background-color: #fea302 !important;\n  border-color: #fea302 !important;\n}\n\n.highlightOption {\n  background-color: white !important;\n  border-color: #fea302 !important;\n  color: #333 !important;\n  opacity: 0.8 !important;\n}\n\n.highlightOption:hover {\n  background-color: #fea302 !important;\n  border-color: #fea302 !important;\n}\n\n.chip {\n  background-color: #fea302 !important;\n  border-color: #fea302 !important;\n  overflow: hidden;\n}\n\n.form-label {\n  margin-bottom: 0.5rem;\n  font-size: 0.9rem;\n  color: black;\n  font-weight: 600;\n}\n\n.form-select-lg {\n  font-size: 0.8rem;\n}\n\n.form-control {\n  font-size: 0.8rem;\n}\n\n.select-heading {\n  font-size: 0.9rem;\n  color: black;\n  font-weight: 600;\n}\n\n.deznav .metismenu li a {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
