// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupon-wrapper {
  width: 100%;
  padding: 100px 50px;
}
.coupon-container {
  border-radius: 10px;
  background: white;
  padding: 1.5rem 2rem;
}
.coupon-first-row {
  margin-top: -50px;
}
.coupon-icons {
  margin: 0 1rem;
}
.coupon-menu-btn {
  background-color: #dc2265;
  padding: 1.25rem;
  margin-right: 1rem;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
}
.coupon-create-btn {
  background-color: #dc2265;
  padding: 0.75rem 2.5rem;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
}
.coupon-form-input {
  width: 100%;
  margin: 1rem 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/jsx/components/table/Coupon/CourseCoupon.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".coupon-wrapper {\n  width: 100%;\n  padding: 100px 50px;\n}\n.coupon-container {\n  border-radius: 10px;\n  background: white;\n  padding: 1.5rem 2rem;\n}\n.coupon-first-row {\n  margin-top: -50px;\n}\n.coupon-icons {\n  margin: 0 1rem;\n}\n.coupon-menu-btn {\n  background-color: #dc2265;\n  padding: 1.25rem;\n  margin-right: 1rem;\n  color: white;\n  border: 2px solid white;\n  border-radius: 5px;\n}\n.coupon-create-btn {\n  background-color: #dc2265;\n  padding: 0.75rem 2.5rem;\n  color: white;\n  border-radius: 5px;\n  margin-top: 1rem;\n}\n.coupon-form-input {\n  width: 100%;\n  margin: 1rem 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
