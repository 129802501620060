import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import profile from "../../images/viosa.png";
import { ThemeContext } from "../../context/ThemeContext";
import { CiUser } from "react-icons/ci";
import { AiOutlineUserAdd } from "react-icons/ai";
import { SiGoogledocs } from "react-icons/si";
import { GoPencil } from "react-icons/go";
import { MdDelete, MdModeEdit, MdOutlineSchedule } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { DeleteInterview, DeleteInterviewById, getAllInterview, searchInterviewByText } from "../../services/apis";
import Loader from "../../Common/Loader/Loader";
import CommonTable from "../../Common/Table/CommonTable";
import DeleteModal from "../../Common/Components/DeleteModal";


const Myinterview = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [InterviewsData, setInterviewsData] = useState([])
  const [loading, setloading] = useState(false)
  const navigate = useHistory()

  const fetchInterviews = async () => {
    try {
      7
      setloading(true)
      const payload = {
        params: {
          collegeId: localStorage.getItem("userId")
        }
      }
      let res;
      if (localStorage.getItem("role") === "COLLEGE") {
        res = await getAllInterview(payload)
      } else {
        res = await getAllInterview()
      }
      console.log(res)
      setInterviewsData(res.data.interviews)
      setloading(false)
    } catch (error) {
      setloading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchInterviews()
  }, [])

  const handleCollegeDelete = async () => {
    try {
      if (value === true) {
        const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
        setInterviewsData(newArr)
        await deleteBatchById(DeletionObject?._id)
      }
      console.log(value)
    } catch (error) {
      console.log(error)
    }
  }


  const columns = [
    {
      name: 'S.No',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Job Title',
      selector: row => row.jobtitle,
      sortable: true,
      grow: 2
    },
    {
      name: 'Job Category',
      selector: row => row.jobcategory,
      sortable: true
    },
    {
      name: 'Domain',
      selector: row => row.domain,
      sortable: true
    },
    {
      name: 'Action',
      selector: row => <div className="d-flex gap-3">
        <button onClick={() => {
          navigate.push(`/interview-pro/interview/add/${row?._id}/new-mock`)
        }} className="p-2 border rounded-2 border-dark cursor-pointer"><MdModeEdit fontSize={24} style={{ color: "blue" }} /> </button>

        <span className="p-2 border rounded-2 border-dark cursor-pointer" onClick={() => {
        }} ><MdDelete onClick={() => ShowDeleteModal(row)} fontSize={24} style={{ color: "red" }} /></span>
      </div>,
    },
  ]


  // deletion 


  // <----------------------------------------delete batch-------------------------------------------->

  const [showDelete, setshowDelete] = useState(false)
  const [DeletionObject, setDeletionObject] = useState()

  const ShowDeleteModal = (value) => {
    setDeletionObject(value)
    setshowDelete(true)
  }
  const HideDeleteModal = () => {
    setshowDelete(false)
  }

  const handleDeleteDecision = async (value) => {
    try {
      if (value === true) {
        const newArr = InterviewsData?.filter((item, index) => item._id !== DeletionObject?._id)
        setInterviewsData(newArr)
        await DeleteInterviewById(DeletionObject?._id)
      }
      console.log(value)
    } catch (error) {
      console.log(error)
    }
  }

  // const handleSearchChange = async (e) => {
  //   if (e.target.value.length > 2) {
  //     try {
  //       const res = await searchInterviewByText(e.target.value)
  //       setInterviewsData(res.data.interviews)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   } else if (e.target.value.length === 0) {
  //     fetchInterviews()
  //   }
  // }




  const csvData = InterviewsData?.map((row, index) => ({
    "S No.": index + 1,
    "Job Title": row?.jobtitle,
    "Job Category": row.jobcategory,
    "Domain": row?.domain,
  }));


  const [searchQuery, setsearchQuery] = useState('')

  const [FilteredData, setFilteredData] = useState([])
  useEffect(() => {
    handleSearchChange()
  }, [searchQuery, InterviewsData]);

  const handleSearchChange = async (e) => {
    if (searchQuery === '') {
      setFilteredData(InterviewsData);
    } else {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filteredResults = InterviewsData.filter(item =>
        Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredData(filteredResults);
    }
  }

  return (
    <>
      <section>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <div className="d-flex ">
            {/* <input type="text" placeholder="Search Interview" className='p-2 rounded-2 border border-dark' onChange={handleSearchChange} /> */}
            {/* <button className="common-btn px-3 p-1 ms-4 rounded ">Find</button> */}
          </div>
          <button className="p-3 rounded mb-4 text-white px-4 " style={{ background: "#521986 " }} onClick={() => navigate.push('/interview-pro/interview/add/new-mock')}>Create Interview</button>
        </div>

        {
          loading ? <Loader /> : <CommonTable csvData={csvData} csvName={"Interview-Data"} data={FilteredData} setsearchQuery={setsearchQuery}
            columns={columns} />

        }
        <DeleteModal DeletionObject={DeletionObject} showDelete={showDelete} ShowDeleteModal={ShowDeleteModal} handleDeleteDecision={handleDeleteDecision} HideDeleteModal={HideDeleteModal} field={DeletionObject?.jobtitle} />


      </section>
    </>
  );
};
export default Myinterview;



