// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataTable th {
    background-color: black !important;
    color: #333;
    font-weight: bold;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Common/Table/commontable.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".dataTable th {\n    background-color: black !important;\n    color: #333;\n    font-weight: bold;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
