// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-card{
    background-color: rgba(240, 248, 255, 0.634);
    border: 2px solid gray;
    border-radius: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.save-button{
    color: white;
    background-color: palevioletred;
    width: 150px;
    height: 34px;
    border: 0;
    border-radius: 5px;
}
.select-button{
    width: 180px;
    height: 42px;
    background-color: rgba(0, 128, 0, 0.116);
    color: green;
    border: 0;
    border-radius: 5px;
}
.session-details{
    color: gray;
    font-size: 28px;
}
.to-column{
    display:grid;
    flex-direction:column;
}
.next-button-1{
    background-color: green;
    color: white;
    width: 120px;
    height: 35px;
    border: 0;
    border-radius: 5px;
}
input[type='file']{
    opacity: 1;
    cursor: pointer;

}
.select-image{
    font-size: 20px;
}
.select-image{
    z-index: 1;
}
.for-hover:hover{
    border: 2px solid blue;
}
.active{
    background-color: darkblue;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/jsx/components/table/Courses/Session/Sessions/Session.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,YAAY;IACZ,+BAA+B;IAC/B,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,wCAAwC;IACxC,YAAY;IACZ,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,YAAY;IACZ,qBAAqB;AACzB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,eAAe;;AAEnB;AACA;IACI,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,0BAA0B;IAC1B,YAAY;AAChB","sourcesContent":[".schedule-card{\n    background-color: rgba(240, 248, 255, 0.634);\n    border: 2px solid gray;\n    border-radius: 10px;\n    padding-right: 10px;\n    padding-bottom: 10px;\n}\n.save-button{\n    color: white;\n    background-color: palevioletred;\n    width: 150px;\n    height: 34px;\n    border: 0;\n    border-radius: 5px;\n}\n.select-button{\n    width: 180px;\n    height: 42px;\n    background-color: rgba(0, 128, 0, 0.116);\n    color: green;\n    border: 0;\n    border-radius: 5px;\n}\n.session-details{\n    color: gray;\n    font-size: 28px;\n}\n.to-column{\n    display:grid;\n    flex-direction:column;\n}\n.next-button-1{\n    background-color: green;\n    color: white;\n    width: 120px;\n    height: 35px;\n    border: 0;\n    border-radius: 5px;\n}\ninput[type='file']{\n    opacity: 1;\n    cursor: pointer;\n\n}\n.select-image{\n    font-size: 20px;\n}\n.select-image{\n    z-index: 1;\n}\n.for-hover:hover{\n    border: 2px solid blue;\n}\n.active{\n    background-color: darkblue;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
