// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_bg_active {
    background-color: #521986;
    color: white !important;
}

.common-btn {
    background-color: #fea302;
    color: white;
}

.interview-pro-college-add-btns {
    border: 2px solid #521986;
    color: #521986;
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: start;
}

.common-highlight-heading {
    background-color: #521986;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/css/common.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".navbar_bg_active {\n    background-color: #521986;\n    color: white !important;\n}\n\n.common-btn {\n    background-color: #fea302;\n    color: white;\n}\n\n.interview-pro-college-add-btns {\n    border: 2px solid #521986;\n    color: #521986;\n    display: flex;\n    align-items: center;\n    padding-left: 20px;\n    justify-content: start;\n}\n\n.common-highlight-heading {\n    background-color: #521986;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
