import React, { useEffect } from 'react'
import { useState } from 'react'
import { CiUser } from 'react-icons/ci'
import { getAllDashboardData } from '../services/apis'
import Loader from '../Common/Loader/Loader'
import { LuUsers } from "react-icons/lu";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GrDocumentPdf } from "react-icons/gr";
import { SiCoursera } from "react-icons/si";
import { GoStack } from "react-icons/go";

function CollegeDashboard() {
    const [loading, setloading] = useState(false)
    const [collegeDashboardData, setcollegeDashboardData] = useState(null)
    useEffect(() => {
        fetchDashboardData()
    }, [])

    const fetchDashboardData = async () => {
        try {
            setloading(true)
            const payload = {
                collegeid: localStorage.getItem("userId")
            }

            const res = await getAllDashboardData(payload)
            setloading(false)
            console.log(res)
            setcollegeDashboardData(res.data.data)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const ROLE = localStorage.getItem("role")
    const userName = localStorage.getItem("userName")
    return (
        <>
            {
                loading ? <Loader /> :
                    <div className=" col-12 d-flex justify-content-between gap-5 flex-column">
                        {
                            ROLE === "COLLEGE" &&
                            <div>
                                <h2>
                                    Welcome
                                </h2>
                                <h5>{userName}</h5>
                            </div>

                        }
                        <div className="d-flex justify-content-between gap-5">
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <LuUsers fontSize={24} />
                                        <h5 className="mb-0">No of Total Users</h5>
                                    </span>
                                    <h3 className="ps-5  mt-3" style={{ fontWeight: 600 }}>{collegeDashboardData?.noofstudent}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <FaUsersViewfinder fontSize={24} />
                                        <h5 className="mb-0">No of Interview Pro Users</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3" style={{ fontWeight: 600 }}>{collegeDashboardData?.interviewprouser}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <GrDocumentPdf fontSize={24} />
                                        <h5 className="mb-0">No of Resumatic Users</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3" style={{ fontWeight: 600 }}>{collegeDashboardData?.resumaticuser}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between gap-5">
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">

                                        <SiCoursera fontSize={24} />
                                        <h5 className="mb-0">No of Courses</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3" style={{ fontWeight: 600 }} s>{collegeDashboardData?.courses}</h3>
                                </div>
                            </div>
                            <div className="w-100 bg-white rounded">
                                <div className="card-body">
                                    <span className="d-flex gap-3 align-items-center">
                                        <GoStack fontSize={24} />
                                        <h5 className="mb-0">No. Of Batches</h5>
                                    </span>
                                    <h3 className="ps-5 mt-3" style={{ fontWeight: 600 }}>{collegeDashboardData?.batches}</h3>
                                </div>
                            </div>
                            {/* <div className="w-100 bg-white rounded">
                        <div className="card-body">
                        <span className="d-flex gap-3 align-items-center">
                        <CiUser fontSize={24} />
                        <h5 className="mb-0">No of Resumatic Users</h5>
                        </span>
                        <h3 className="ps-5 mt-3">20</h3>
                        </div>
                    </div> */}
                        </div>
                    </div>
            }
        </>
    )
}

export default CollegeDashboard