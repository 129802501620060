// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graphContainer {
  background: white;
  box-shadow: 0px 0px 40px rgba(196, 196, 196, 0.15);
  border-radius: 20px;
  padding: 0.5rem 0.5rem;
  margin: 15px 0;
}

.graphContainer .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.graphContainer .heading .left {
  font-weight: 600;
  font-size: 18px;
  border-left: 8px solid #58a697;
  padding-left: 10px;
}

.graphContainer .heading .right {
  margin: 10px 0;
  font-weight: 600;
  font-size: 15px;
  color: #ffa303;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background: #fff2e0;
}

.graphContainer .heading .right .item {
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.graphContainer .heading .right .selected {
  background: #ffa303;
  border-radius: 5px;
  color: white;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 768px) {
  #res {
    margin-left: -35px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/jsx/components/Graphjs/graph.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kDAAkD;EAClD,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".graphContainer {\n  background: white;\n  box-shadow: 0px 0px 40px rgba(196, 196, 196, 0.15);\n  border-radius: 20px;\n  padding: 0.5rem 0.5rem;\n  margin: 15px 0;\n}\n\n.graphContainer .heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.graphContainer .heading .left {\n  font-weight: 600;\n  font-size: 18px;\n  border-left: 8px solid #58a697;\n  padding-left: 10px;\n}\n\n.graphContainer .heading .right {\n  margin: 10px 0;\n  font-weight: 600;\n  font-size: 15px;\n  color: #ffa303;\n  height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  border: 1px solid #e5e5e5;\n  border-radius: 8px;\n  background: #fff2e0;\n}\n\n.graphContainer .heading .right .item {\n  padding: 0 10px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.graphContainer .heading .right .selected {\n  background: #ffa303;\n  border-radius: 5px;\n  color: white;\n  height: 100%;\n  transition: all 0.5s ease-in-out;\n}\n\n@media (max-width: 768px) {\n  #res {\n    margin-left: -35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
