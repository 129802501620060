// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelButton {
    display: flex;
    padding: 10px 15px;
    border: 2px solid grey;
    background: lightgray;
    align-items: center;

}

.labelButton button {
    margin-left: 20px;
    color: white;
    background: red;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/multiselect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;;AAEvB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".labelButton {\n    display: flex;\n    padding: 10px 15px;\n    border: 2px solid grey;\n    background: lightgray;\n    align-items: center;\n\n}\n\n.labelButton button {\n    margin-left: 20px;\n    color: white;\n    background: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
